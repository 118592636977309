import React, { useState, useEffect } from "react";
import { Text, View, TouchableOpacity, TextInput, Image, Linking, Alert} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import UploadFile from "../../Functions/PickImage";
import UploadBd from "../../Functions/Upload";
import { collection, query, where, getDocs, getFirestore } from "firebase/firestore";
import styles from "./style";
import { Picker } from '@react-native-picker/picker';

import  * as DocumentPicker from 'expo-document-picker';
import { getAuth } from "firebase/auth";


export default function Video() {

  const auth = getAuth();
  const user = auth.currentUser;

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [botao, setBotao] = useState("Enviar vídeo");
  const [dataAtual, setDataAtual] = useState("");
  const [permissao, setPermissao] = useState("");
  const [diplo, setDiplo] = useState("");
  const [grupo, setGrupo] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openAnimation, setOpenAnimation] = useState(false)

  const [p1, setP1] = useState("");
  const [p2, setP2] = useState("");
  const [p3, setP3] = useState("");
  const [p4, setP4] = useState("");
  const [p5, setP5] = useState("");
  const [p6, setP6] = useState("");


  const [retrono, setRetorno] = useState([]);


  const [doc, setDoc] = useState("");
  const [arquivo, setArquivo] = useState("");
  const [loading, setLoading] = useState(false);
  const [larg, setLarg] = useState(null);

  const Enviando = "https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/OX%2FEnviando%20(1).gif?alt=media&token=96439257-6e11-49dd-8992-29df070984c2";
  const Enviado = "https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/OX%2FUpload%20feito%20(1).gif?alt=media&token=7b184fa3-3487-49d9-bbb3-82a7ce137f03";
  const Up = "https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/DAO%2FOX.gif?alt=media&token=a899dfc0-907c-47d6-8b54-adef1b7090d5";

  const Tick = () => {
    var data = new Date();

    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    setDataAtual(dia + "/" + mes + "/" + ano);
  };

  const Envio = async () => {
    if (nome !== "" || email !== "" || arquivo !== "" || diplo !== ""){
      const BackEnd = await UploadBd(arquivo, nome, email, dataAtual, diplo, grupo);

      if(BackEnd ===  "Concluido"){
        setOpenModal(true)
        setOpenAnimation(false)
      }
    } else {
      alert("No fue posible enviar, ya que hay campos vacíos, rellene todos los campos para enviar de nuevo")
    }
  }

  setInterval(() => {
    var largura = window.screen.width;
    setLarg(largura);
  }, 100);

  const getUploader = async () => {
   if(loading === false && arquivo === "") {
    setLoading(true);
   } else {
    setLoading(false);
   }
  }

  const getNome = async () => {
    for (var i = 0; i <= 2; i++) {
        const db = getFirestore();
        
        const citiesRef = collection(db, "Usuarios");
        const q = query(citiesRef, where("email", "==", email));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setNome(doc.data().nome);
        });
    }
  }

  const Grupo = async () => {

      var controle;
      const db = getFirestore();
        
      const citiesRef = collection(db, "Usuarios");
      const q = query(citiesRef, where("email", "==", email), where("diplo", "==", diplo));

      const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          controle = String(doc.data().diplo)
          setGrupo(doc.data().grupo);
        });
        console.log(controle);
        console.log(diplo);
        await Controle_diplo(controle, diplo)
  }

  async function Controle_diplo (controle, diplo) {
    if(controle === /*diplo 'Formula'*/ ""){
      setPermissao("negado")
      alert("Solo los estudiantes pueden enviar testimonios")
    }else {
      setPermissao("permitido")
    }
  }

  const pickImage = async () => {

    try{
        let result = await DocumentPicker.getDocumentAsync({
            type: "*/*",
            copyToCacheDirectory: false,
            multiple: false,
        });
        
        console.log(result);

       
        const mimeType = result.mimeType;
        const extensao = identificarExtensao(mimeType);
        
        if (!result.canceled) {
        setBotao("Espere...")
          var lastThree = result.name.substr(result.name.length - 3);

          setDoc(result.name)
          
          const File = await UploadFile(result.uri, extensao, mimeType);
          getUploader();
        
          setArquivo(File)
        } else {
          setLoading(false)
        }
  
    } catch(error) {

    }
  }


  function identificarExtensao(mimeType) {
    const mapeamento = {
        'video/mp4': '.mp4',
        'video/mpeg': '.mpeg',
        'video/quicktime': '.mov',
        'video/x-msvideo': '.avi',
        'video/x-flv': '.flv',
        'video/webm': '.webm',
        'video/x-matroska': '.mkv',
        'video/x-ms-wmv': '.wmv',
        'video/x-ms-asf': '.asf',
        'video/x-m4v': '.m4v',
        'video/3gpp': '.3gp',
        'video/3gpp2': '.3g2',
        'video/x-msvideo': '.avi',
        'video/vnd.dlna.mpeg-tts': '.m2ts',
        'video/x-dv': '.dv',
        'video/x-f4v': '.f4v',
        'video/x-mng': '.mng',
        'video/x-ms-vob': '.vob',
        'video/x-sgi-movie': '.movie',
        // Adicione mais tipos MIME e extensões conforme necessário
    };
    
    return mapeamento[mimeType] || null;
  }

  async function Caso() {
    const db = getFirestore();
    const citiesRef = collection(db, "Resumos");
    const q = query(citiesRef, where("tipo", "==", "testemunho"), where("email", "==", email), where("concluido", "==", "Ok"));
    let array = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
    
      const Obj = {
        id: doc.id,
        email: doc.data().email,
      };

      array.push(Obj);
    });
    setRetorno(array);
  }

  useEffect( () => {
    if(arquivo !== "") {
      getUploader();
      Tick();
    }
  },[arquivo])


  useEffect( () => {
      Caso();
  },[nome])

  useEffect( () => {
      getNome();
  },[email])

  useEffect( () => {
    if(diplo !== ""){
      Grupo();
    }
  },[diplo])
  

  useEffect(() => {
    if (user !== null) {
        user.providerData.forEach((profile) => {
            setEmail(profile.email)
        });
    } 
},[]);

  return (

      <View style={styles.ContainerBody}>
        {openAnimation === true &&
        
          <View style={styles.containerAnimation}>
          <Image
              style={styles.animation}
              source={{uri : Enviando}}
          />
         </View>
        }
        
        {openModal === true || retrono.length > 0 ? 
          <View style={styles.containerAnimation}>
            <Image
                style={styles.animationEnviado}
                source={require("../../../assets/check.svg")}
            />
            <Text style={styles.titleAnimationEnviado}>Su testimonio ha sido enviado!</Text>
            <Text style={styles.textAnimationEnviado}>Le enviamos un correo electrónico de confirmación de envío ✅</Text>
          </View>
          :
          <>
        
          {openAnimation === false &&
            <>
            <View style={larg >= 601 ? styles.box : styles.box_mobile}>
    
              <View style={{height: 40}}/>

              <Text style={larg >= 601 ? styles.text : styles.text_mobile_2}>Elija tu diplomado y después pulsa el botón para subir tu video</Text>

              <Picker
                selectedValue={diplo}
                style={larg >= 601 ? [styles.input, styles.shadowProp] : [styles.input_mobile, styles.shadowProp_mobile]}
                onValueChange={(itemValue) => setDiplo(itemValue)}>

                <Picker.Item label="Diplomado" value="Diplomado" />
                <Picker.Item label="Fórmula Correctiva" value="Formula" />
                <Picker.Item label="Intercept" value="Intercept" />
                                            
              </Picker>


              {loading === false && arquivo !== "" &&
                <Text style={larg >= 601 ? styles.text : styles.text_mobile_2}>Si desea cambiar el video, haga clic en "Video Adjunto"</Text>
              }

              <View style={styles.divBottom}>
                {loading === false && arquivo === "" && permissao !== "negado" &&
                  <TouchableOpacity style={[styles.bottom, styles.shadowProp2]} onPress={() => {pickImage()}}>
                    <Text style={styles.textBottom}>{botao}</Text>
                  </TouchableOpacity>
                }

                {loading === true && arquivo === "" &&
                  <Image
                    style={{width: 50, height: 50, marginTop: 20, alignItems: 'center'}}
                    source={{uri : Up}}
                  />
                }

                {loading === false && arquivo !== "" &&
                  <TouchableOpacity style={styles.upload} onPress={() => {setArquivo(""), setLoading(false), pickImage()}}>
                    <Text style={styles.textUpload}>Video Adjunto</Text>
                  </TouchableOpacity>
                }
                {/*Upload*/}

              </View>

            {loading === false && arquivo !== "" &&
              <TouchableOpacity style={[styles.bottomEnviar, styles.shadowProp2]} onPress={() => {Envio()}}>
                  <Text style={styles.textBottom}>Enviar</Text>
              </TouchableOpacity>
            }
            </View>
          
          </>
          }
        </>
        }
    </View>
    
     
  );
}
