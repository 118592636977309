import { Platform, StyleSheet } from "react-native";
const styles = StyleSheet.create({

    container: {
        flex: 1,
        alingnItens: 'center',
        justifyContent: 'center',
        zoom: 0.65,
    },

    title: {
        fontSize: 58,
        marginBottom: 25,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#fff',
        marginTop: 160,
    },
    form: {
        width: 300,
        marginTop: 40,
        padding: 10,
        height: 50,
        borderBottomWidth: 1,
        borderBottomColor: "#fff",
        marginLeft: "auto",
        marginRight: "auto",
        alingnItens: 'center',
        justifyContent: 'center',
    },
    buttonLogin: {
        width: 200,
        height: 50,
        justifyContent: 'center',
        backgroundColor: '#fff',
        borderRadius: 50,
        marginTop: 70,
        marginBottom: 20,
    },

    textButtonLogin: {
        color: "#000",
        textAlign: 'center',
    },
    contentAlert: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',

    },
    warningAlert: {
        paddingLeft: 10,
        color: "#FFE500",
        fontSize: 16,

    },
    registation: {
        marginTop: 20,
        color: '#4d5156',
        textAlign: 'center',

    },

    linkSubscribe: {
        color: '#fff',
        fontSize: 20,
        textAlign: 'center',
        marginTop: 20,
    },

    divImg: {
        alingnItens: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        position: "absolute",
        opacity: .2,
    },

    img: {
        width: 1300,
        height: 870,
    },
});

export default styles