import React, { useState, useEffect } from "react";
import {View, TouchableOpacity, Image} from "react-native";

import { MaterialCommunityIcons } from "@expo/vector-icons";
import styles from "./style";
import Header from "../../Components/Header";
import Video from "../../Components/Video";

import firebase from "../../Config/firebaseconfig";

export default function Home({navigation}) {
  return (
    <View style={styles.container}>
        <TouchableOpacity
            style={{position: 'absolute', top: 0, left: 0, margin: 30, zIndex: 100}}
            onPress={() => navigation.goBack()}
        >
            <Image style={{width: 40, height: 40}} source={require("../../../assets/reply.svg")} />
        </TouchableOpacity>
      <Header/>
      <Video/>
    </View>
  );
}
