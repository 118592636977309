import React, { useEffect, useState } from "react"
import { View, Text, TextInput, TouchableOpacity, Platform, Image, ScrollView, SafeAreaView} from "react-native";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import styles from "./style"
import { LinearGradient } from 'expo-linear-gradient';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useFocusEffect } from "@react-navigation/native";
import * as Animatable from 'react-native-animatable'
import firebase from "../../Config/firebaseconfig";



export default function Login({ navigation }) {

    const [email, setEmail] = useState("")
    const [senha, setSenha] = useState("")
    const [errorRegister, setErrorRegister] = useState("")
    const [logar, setLogar] = useState("")
    const [apple, setApple] = useState(false)


    const login = () => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, senha)
            .then((userCredential) => {
                let user = userCredential.user;
                navigation.navigate("Home")
            })
            .catch((error) => {
                setErrorRegister(true)
                let errorCode = error.code;
                let errorMessage = error.message; 
            
            });

    }

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                navigation.navigate("Home")
            }
         });
    }, []);


    const handleKeyPress = ({ nativeEvent: { key: keyValue } }) => {
        console.log(keyValue);
        if(keyValue === 'Enter'){  
          setLogar("Ativar")
        }
    };

    useFocusEffect(
        React.useCallback(() => {
         setSenha("")
         setErrorRegister(false)
         setLogar("");

        }, [])
      );

      useEffect(() => {
        if(logar ==="Ativar"){
            login()
        }
    },[logar])

    useEffect(() => {
        if(errorRegister === true){
            setSenha("")
            setLogar("")
        }
    },[errorRegister])


    

    return (
        
        <LinearGradient style={styles.container}
            colors={['#09515B', '#0D0D0D']}>

        <SafeAreaView>
            
                <View style={styles.divImg}>
                    <Animatable.Image 
                        animation= "fadeInDown"
                        style={styles.img} source={{
                        uri: 'https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---BRANCO.png?alt=media&token=7645261d-d233-404b-bfd9-b61b172ce0d0',
                        }} />
                </View>

                <Animatable.Text 
                animation="fadeInLeftBig"
                style={styles.title}> Login </Animatable.Text>

                <Animatable.View 
                animation="slideInLeft"
                >
                <TextInput 
                style={{
                    alignItems: 'center', width: 500, marginTop: 30, padding: 10,
                    height: 60, borderBottomWidth: 1, borderBottomColor: "#fff", marginLeft: "auto",
                    marginRight: "auto", color: "#fff", fontSize: 15, fontSize: 17, fontWeight: "bold"
                }}
                    placeholder="Correo electrónico..."
                    placeholderTextColor="#fff"
                    type="text"
                    onChangeText={(text) => setEmail(text)}
                    value={email} />
                    </Animatable.View>

                <Animatable.View 
                animation="slideInRight"
                >
                <TextInput 
                style={{
                    alignItems: 'center', width: 500, marginTop: 30, padding: 10,
                    height: 60, borderBottomWidth: 1, borderBottomColor: "#fff", marginLeft: "auto",
                    marginRight: "auto", color: "#fff", fontSize: 15, fontSize: 17, fontWeight: "bold"
                }}
                    secureTextEntry={true}
                    placeholder="Contraseña..."
                    placeholderTextColor="#fff"
                    type="text"
                    onChangeText={(text) => setSenha(text)}
                    value={senha} 
                    onKeyPress={handleKeyPress}
                    />
                    </Animatable.View>


                {errorRegister === true

                    ?
                    <View style={styles.contentAlert}>
                        <MaterialCommunityIcons
                            name="alert-circle"
                            size={24}
                            color="#FFE500" />
                        <Text style={styles.warningAlert}>E-mail o contraseña incorrectos</Text>
                    </View>

                    :

                    <View />
                }

                <View style={{ alignSelf: 'center' }}>

                    {email === "" || senha === ""

                        ?
                    <Animatable.View
                    animation="zoomIn"
                    >
                        <TouchableOpacity
                            animation="zoomIn"
                            disabled={true}
                            style={styles.buttonLogin}
                        >
                            <Text style={styles.textButtonLogin}>Ingresar</Text>
                        </TouchableOpacity>
                        </Animatable.View>

                        :

                        <TouchableOpacity
                            style={styles.buttonLogin}
                            onPress={login}
                        >
                            <Text style={styles.textButtonLogin}>Ingresar
                            </Text>
                        </TouchableOpacity>

                    }

                </View>

                <Text style={styles.registation}>
                    <TouchableOpacity onPress={() => navigation.navigate("Trocadesenha")}>
                        <Text style={styles.linkSubscribe}>
                            ¿Primer acceso?
                        </Text>
                    </TouchableOpacity>
                </Text>


                <Text style={styles.registation}>

                    <TouchableOpacity onPress={() => navigation.navigate("Trocadesenha2")}>
                        <Text style={styles.linkSubscribe}>
                            Olvidé mi contraseña
                        </Text>
                    </TouchableOpacity>

                </Text>
            
                <View style={{ height: 100 }} />
        </SafeAreaView>
        </LinearGradient>
    );

} 