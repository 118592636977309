import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import Home from "./src/Pages/Home";
import Login from "./src/Pages/Login";
import Trocadesenha from "./src/Pages/Trocadesenha";
import Trocadesenha2 from "./src/Pages/Trocadesenha2";

import Video from "./src/Components/Video";


const Stack = createStackNavigator();

export default function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator>

      <Stack.Screen
          name="Login"
          component={Login}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              backgroundColor: "#054A59",
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Trocadesenha"
          component={Trocadesenha}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              backgroundColor: "#054A59",
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Trocadesenha2"
          component={Trocadesenha2}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              backgroundColor: "#054A59",
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Home"
          component={Home}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              backgroundColor: "#054A59",
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Video"
          component={Video}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              backgroundColor: "#054A59",
              shadowColor: "transparent",
            },
          }}
        />

      </Stack.Navigator>
    </NavigationContainer>
  );
}
