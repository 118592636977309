import React, {useState}from "react";
import {View, ImageBackground,} from "react-native";
import styles from "./style";


export default function Header() {

  const [larg, setLarg] = useState(null);

  setInterval(() => {
    var largura = window.screen.width;
    setLarg(largura);
  }, 100);

  return (
    <View style={larg >= 601 ? styles.ContainerHeader : styles.ContainerHeader_2}>
      <ImageBackground
        source={larg >= 601 ? require("../../../assets/bg.svg") : require("../../../assets/bg_mobile.svg")} 
        style={larg >= 601 ? styles.background : styles.background_2}
      >
      </ImageBackground>
    </View>
  );
}
