import React, { useEffect, useState } from "react"
import { LogBox } from 'react-native';
import { View, Text, TextInput, TouchableOpacity, Platform, SafeAreaView, ScrollView} from "react-native";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import styles from "./style"
import { LinearGradient } from 'expo-linear-gradient';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useFocusEffect } from "@react-navigation/native";
import * as Animatable from 'react-native-animatable'
import firebase from "../../Config/firebaseconfig";


export default function Trocadesenha({ navigation }) {

   
    const [senha, setSenha] = useState("")
    const [logar, setLogar] = useState("")
    const [apple, setApple] = useState(false)


    const [email, setEmail] = useState("");
    const [errorRegister, setErrorRegister] = useState("");

    const Redefinir = async () => {
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                alert('Enlace para restablecer la contraseña enviado a su correo electrónico. Si no lo encuentras, revisa tu carpeta de spam o envíalo de nuevo.')
                navigation.navigate("Login")

            })
            .catch((error) => {
                setErrorRegister(true)
                const errorCode = error.code;
                const errorMessage = error.message;

            });

    }

    const handleKeyPress = ({ nativeEvent: { key: keyValue } }) => {
        console.log(keyValue);
        if(keyValue === 'Enter'){  
          setLogar("Ativar")
        }
    };

    useFocusEffect(
        React.useCallback(() => {
         setSenha("")
         setEmail("")
         setErrorRegister(false)
         setLogar("");

        }, [])
      );

      useEffect(() => {
        if(logar ==="Ativar"){
            Redefinir();
        }
    },[logar])

    useEffect(() => {
        if(errorRegister === true){
            setEmail("")
            setSenha("")
            setLogar("")
        }
    },[errorRegister])

    

    return (
        
        <LinearGradient style={styles.container}
            colors={['#09515B', '#0D0D0D']}>
    
        <View style={styles.header}>
                <View>
                    <Text style={styles.Title}>Primer acceso</Text>
                </View>

                <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.navigate("Login")} >
                    <MaterialCommunityIcons
                        name="reply"
                        size={50}
                        color="#ffffff"
                        style={{justifyContent: 'center', alignContent: 'center' }}
                        />
                </TouchableOpacity>
                
            </View>

            <Animatable.Text 
            animation="fadeInLeftBig"
            style={styles.title}> Escriba su correo electrónico correctamente </Animatable.Text>

            <Animatable.View 
            animation="slideInLeft"
            >
            <TextInput 
            style={{
                alignItems: 'center', width: 500, marginTop: 30, padding: 10,
                height: 60, borderBottomWidth: 1, borderBottomColor: "#fff", marginLeft: "auto",
                marginRight: "auto", color: "#fff", fontSize: 20, fontWeight: "bold"
            }}
                placeholder="Correo electrónico..."
                placeholderTextColor="#fff"
                type="text"
                onChangeText={(text) => setEmail(text)}
                value={email} 
                onKeyPress={handleKeyPress}
                />
                </Animatable.View>

            {errorRegister === true

                ?
                <View style={styles.contentAlert}>
                    <MaterialCommunityIcons
                        name="alert-circle"
                        size={24}
                        color="#FFE500" />
                    <Text style={styles.warningAlert}>E-mail o contraseña incorrectos</Text>
                </View>

                :

                <View />
            }

            <View style={{ alignSelf: 'center' }}>

                {email === "" 

                    ?
                  <Animatable.View
                  animation="zoomIn"
                  >
                    <TouchableOpacity
                        animation="zoomIn"
                        disabled={true}
                        style={styles.buttonLogin}
                    >
                        <Text style={styles.textButtonLogin}>Enviar</Text>
                    </TouchableOpacity>
                    </Animatable.View>

                    :

                    <TouchableOpacity
                        style={styles.buttonLogin}
                        onPress={Redefinir}
                    >
                        <Text style={styles.textButtonLogin}>Enviar
                        </Text>
                    </TouchableOpacity>

                }

            </View>

            <View style={{ height: 100 }} />
           
        
        </LinearGradient>
    );

} 