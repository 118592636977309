import { collection, getFirestore, addDoc, query, where, getDocs, db} from "firebase/firestore";
import axios from "axios";


export default async function UploadBd(arquivo, nome, email, dataAtual, diplo, grupo) {

        let Title = "";
        let NomeDiplo = "";
        let Url = "";
        if(diplo === "Formula"){
            Title = "FC" + grupo.substring(5);
            NomeDiplo = "Fórmula Correctiva";
            Url = "https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Formula%2FWhatsApp%20Image%202024-03-07%20at%2011.03.55.jpeg?alt=media&token=9fd7fe80-3123-4ae6-b912-3a6379726ab5";
        };

        if(diplo === "Intercept"){
            Title = "G" + grupo.substring(5);
            NomeDiplo = "Intercept";
            Url = "https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Intercept%2FE-mails.png?alt=media&token=f5d60b04-2283-4c28-8270-05fcc5a2e1fb";
        };
            const db = getFirestore();
            const docRef = await addDoc(collection(db, "Resumos"), {
                to: [email],
                message: {
                    subject: Title + ' - Recibimos su testimonio',
                    html: `<style>
                    *{
                    font-family: sans-serif;
                    font-Size: 15px
                    }
                </style>

                <img src="`+ Url +`" width="100%"/>
                
                <p>Hola Doc. ` + nome + `, ¡saludos! </p>
                <p>Recibimos su video correctamente, gracias por el envío y por sus palabras. 😊</p>
                
                <p>Está participando oficialmente en el concurso de testimonios del Diplomado ` + NomeDiplo + ` Grupo ` + grupo.substring(5) + `. 🤗😃<br/><br/></p> 
                
                <p>Un abrazo y bonita semana.<br/><br/></p>
                
                <p>Equipo de Soporte Profª Rayane Pinto<br>
                Odontología Sin Fronteras<br><br></p>
                <div align="center">
                    <img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150">
                </div>`,
                },

                tipo: "testemunho",
                midia: "video",
                email: email,
                arquivo: arquivo,
                nome: nome,
                dataAtual: dataAtual,
                diplo: diplo,
                grupo: grupo,
                concluido: "Ok"
                
            });

            axios.post('https://hook.us1.make.com/yt7532hm8ybkrvwbvb4223v6uu9hlo8v', {
                tipo: "testemunho",
                midia: "video",
                email: email,
                arquivo: arquivo,
                nome: nome,
                dataAtual: dataAtual,
                diplo: diplo,
                grupo: grupo,
                concluido: "Ok"         
            })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.error(error);
            });
       
    return("Concluido")
}