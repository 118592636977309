import { Platform, StyleSheet } from "react-native";
const styles = StyleSheet.create({
  ContainerHeader: {
    height: 611,
    justifyContent: 'center',
  },

  ContainerHeader_2: {
    height: 868,
    justifyContent: 'center',
  },

  background: {
    flex: 1,
    resizeMode: "cover",
    height: 611,
  },

  background_2: {
    flex: 1,
    resizeMode: "cover",
    height: 868,
  },
});

export default styles;
